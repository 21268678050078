import { FlashList, ListRenderItemInfo } from '@shopify/flash-list';
import { useCallback, useMemo } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { MenuItemSkeletonLoading } from '@fhs/menu/src/components/menu-item-skeleton';
import {
  ActionSheet,
  Button,
  ErrorCard,
  MediaQueriesContext,
  ProductListTile,
  Text,
  mobileMediaQueries,
  tokens,
} from '@fhs/ui';
import { ICartEntry } from '@rbi-ctg/menu';

import { ProductWizard } from '../../components';
import { useLegacyMenuStates } from '../../hooks/use-legacy-menu-states';
import {
  addCartEntryImageAndUrl,
  useEditStepSelectedEntry,
  useOfferCart,
  useSelectedStep,
} from '../../state/offer-guide-cart-state';

import { BottomButtonContainer } from './bottom-button';
import { SummaryStepItem } from './summary-step-item';

const StepView = Platform.select({
  native: ({ children, isGoingForward = true }) => (
    <Animated.View
      style={{ flex: 1 }}
      entering={isGoingForward ? SlideInRight : SlideInLeft}
      exiting={isGoingForward ? SlideOutLeft : SlideOutRight}
    >
      {children}
    </Animated.View>
  ),
  web: ({ children }) => <View style={{ flex: 1 }}>{children}</View>,
}) as any;

const ContentView = Platform.select({
  native: ({ children, style = {} }) => (
    <Animated.View style={style} entering={FadeIn.duration(500)} exiting={FadeOut}>
      {children}
    </Animated.View>
  ),
  web: ({ children, style = {} }) => <View style={style}>{children}</View>,
}) as any;

const numColumns = 2;
const renderItem = ({ item, index }: ListRenderItemInfo<any>) => {
  const isFirstColumn = index % numColumns === 0;
  const isLastColumn = index % numColumns === numColumns - 1;
  return (
    <View
      style={[styles.item, isFirstColumn && styles.firstColumn, isLastColumn && styles.lastColumn]}
    >
      <ProductListTile size="lg" {...item} />
    </View>
  );
};

function EligibleItemRenderer({
  productId,
  addItemToSimplyOfferCart,
}: {
  productId: string;
  addItemToSimplyOfferCart: (cartEntry: ICartEntry) => void;
}) {
  const { product, loading } = useLegacyMenuStates({
    productId,
  });
  const { bottom } = useSafeAreaInsets();

  if (loading) {
    return (
      <MediaQueriesContext.Provider value={mobileMediaQueries}>
        <View style={styles.loading}>
          <MenuItemSkeletonLoading hideFooter />
        </View>
      </MediaQueriesContext.Provider>
    );
  }

  if (product) {
    return (
      <ContentView
        key={product?._id}
        style={{
          flex: 1,
          paddingBottom: bottom,
        }}
      >
        <ProductWizard
          product={product}
          isSimplyOffer
          addItemToSimplyOfferCart={addItemToSimplyOfferCart}
        />
      </ContentView>
    );
  }

  return <ErrorCard description="Product not found" hideButton />;
}

export function Content() {
  const {
    currentStepIndex,
    isGoingForward,
    modifyItemSelectionInStep,
    selectedEligibleItem,
    setSelectedEligibleItem,
    goToNextNotDoneStep,
  } = useOfferCart();
  const { editStepSelectedEntry } = useEditStepSelectedEntry();

  const selectedStep = useSelectedStep();

  const hasSelectedEntry = !!selectedStep?.selectedEntry;

  const eligibleItemsFormatted = useMemo(
    () =>
      selectedStep?.eligibleItems?.map(eligibleItem => ({
        id: eligibleItem.slug,
        title: eligibleItem.displayName,
        description: eligibleItem.descriptionShort,
        badge: eligibleItem.badge,
        image: {
          asset: {
            uri: eligibleItem.image?.asset?.uri,
            blurHash: eligibleItem.image?.asset?.blurHash,
          },
          altText: eligibleItem.image?.altText,
        },
        isAvailable: eligibleItem.isAvailable,
        onPress: () => setSelectedEligibleItem(eligibleItem),
      })),
    [selectedStep?.eligibleItems, setSelectedEligibleItem]
  );

  const onPressCustomize = useCallback(() => {
    editStepSelectedEntry(selectedStep, currentStepIndex);
  }, [selectedStep, currentStepIndex, editStepSelectedEntry]);

  return (
    <>
      {!selectedEligibleItem && !hasSelectedEntry && (
        <View style={styles.contentHeader}>
          {selectedStep?.title && <Text.Ui size="xs">{selectedStep?.title}</Text.Ui>}
          {selectedStep?.subtitle && (
            <Text.Heading type="three">{selectedStep?.subtitle}</Text.Heading>
          )}
        </View>
      )}
      <StepView key={currentStepIndex} isGoingForward={isGoingForward}>
        <View style={{ flex: 1 }}>
          {selectedEligibleItem ? (
            <EligibleItemRenderer
              productId={selectedEligibleItem._legacySlug}
              addItemToSimplyOfferCart={cartEntry => {
                setSelectedEligibleItem(undefined);
                modifyItemSelectionInStep(
                  currentStepIndex,
                  addCartEntryImageAndUrl(cartEntry, selectedEligibleItem)
                );
              }}
            />
          ) : (
            <ContentView style={styles.content}>
              {hasSelectedEntry && (
                <View style={{ paddingTop: 12 }}>
                  <Text.Ui size="md" weight="semibold">
                    Customize Your Selection
                  </Text.Ui>
                  <SummaryStepItem
                    buttonLabel="Customize"
                    style={styles.summaryStepItem}
                    selectedEntry={selectedStep.selectedEntry}
                    onPressButton={onPressCustomize}
                  />
                  <View style={styles.dividerContainer}>
                    <View style={styles.dividerLine} />
                    <Text.Ui size="md">or</Text.Ui>
                    <View style={styles.dividerLine} />
                  </View>
                  <Text.Ui style={{ paddingTop: 16 }} size="md" weight="semibold">
                    Replace Your Selection:
                  </Text.Ui>
                </View>
              )}
              <FlashList
                showsVerticalScrollIndicator={false}
                data={eligibleItemsFormatted}
                estimatedItemSize={200}
                renderItem={renderItem}
                numColumns={numColumns}
                renderScrollComponent={ActionSheet.ScrollView}
              />
              {hasSelectedEntry && (
                <View style={{ marginHorizontal: -16 }}>
                  <BottomButtonContainer>
                    <Button size="xl" onPress={goToNextNotDoneStep}>
                      <Button.Text>Skip</Button.Text>
                    </Button>
                  </BottomButtonContainer>
                </View>
              )}
            </ContentView>
          )}
        </View>
      </StepView>
    </>
  );
}

const styles = StyleSheet.create({
  contentHeader: {
    alignItems: 'center',
    gap: 4,
  },
  summaryStepItem: {
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    marginTop: 12,
    borderRadius: 8,
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    marginTop: 16,
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity10,
  },
  content: {
    flex: 1,
    paddingHorizontal: 16,
  },
  loading: {
    flex: 1,
    backgroundColor: tokens.colors.$white,
  },
  item: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 12,
  },
  firstColumn: {
    paddingLeft: 0,
  },
  lastColumn: {
    paddingRight: 0,
  },
  buttonContainer: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
  button: {
    margin: 16,
  },
});
