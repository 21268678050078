import { useIntl } from 'react-intl';
import { Pressable } from 'react-native';

import { Text, TextProps } from '@fhs/ui';
import { Box, BoxProps, Icon } from '@fhs-legacy/universal-components';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const DeliveryFeesApply = ({
  containerProps = {},
  textProps = {},
}: {
  containerProps?: BoxProps;
  textProps?: TextProps;
}) => {
  const { formatMessage } = useIntl();
  const { setShowFeeWarning, serviceMode } = useServiceModeContext();

  if (serviceMode !== ServiceMode.DELIVERY) {
    return;
  }
  return (
    <Box flexDirection="row" gap={2} alignItems="center" marginBottom={3} {...containerProps}>
      <Text style={{ fontSize: 12 }} weight="bold" {...textProps}>
        {formatMessage({
          id: 'deliveryFeesApply',
        })}
      </Text>
      <Pressable onPress={() => setShowFeeWarning(true)}>
        <Icon variant="info" size="$4" {...hiddenAccessibilityPlatformProps} />
      </Pressable>
    </Box>
  );
};
