import { useLocalSearchParams } from 'expo-router';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Button,
  IconChevronLeft,
  IconClose,
  ItemStepper,
  Skeleton,
  StepProps,
  Text,
  tokens,
} from '@fhs/ui';
import { isWeb } from '@fhs/utils';

import { useOfferDetailUi } from '../../queries/loyalty.queries';
import { useIsItemInCart, useOfferCart } from '../../state/offer-guide-cart-state';

interface NavigationProps {
  onPressPrev: () => void;
  onPressStep: (index: number) => void;
  onClose: () => void;
}

export function Navigation({ onPressPrev, onPressStep, onClose }: NavigationProps) {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const { isLoading } = useOfferDetailUi(offerId);

  const { steps: offerSteps, currentStepIndex, showSummary, selectedEligibleItem } = useOfferCart();
  const isItemInCart = useIsItemInCart();
  const isBackButtonDisabled = currentStepIndex === 0 && !selectedEligibleItem;

  const steps = useMemo(
    () =>
      offerSteps.map<StepProps>((offerStep, index) => ({
        image: offerStep?.selectedEntry?.image?.asset?.url,
        isActive: currentStepIndex === index,
      })),
    [offerSteps, currentStepIndex]
  );
  const hasOneStep = steps.length === 1;

  if (isItemInCart && !isWeb) {
    return <View style={{ paddingTop: 20 }} />;
  }

  return (
    <View style={styles.navigation}>
      <Button
        disabled={isBackButtonDisabled}
        type="unstyled"
        size="xl"
        onPress={onPressPrev}
        style={{ alignSelf: 'center' }}
      >
        <Button.Icon>
          <IconChevronLeft size={24} />
        </Button.Icon>
      </Button>
      <View style={styles.itemStepperContainer}>
        {isLoading && <Skeleton style={styles.itemStepperSkeleton} />}
        {!isLoading && !isItemInCart && !hasOneStep && (
          <ItemStepper steps={steps} onStepClick={onPressStep} />
        )}
        {!isLoading && hasOneStep && !showSummary && !isItemInCart && (
          <Text.Ui size="md" weight="semibold" style={{ alignSelf: 'center' }}>
            Item Selection
          </Text.Ui>
        )}
      </View>
      <Button type="ghost" size="xl" onPress={onClose} style={{ alignSelf: 'center' }}>
        <Button.Icon>
          <IconClose size={24} />
        </Button.Icon>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  itemStepperContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexGrow: 1,
    flex: 1,
    overflow: 'hidden',
  },
  itemStepperSkeleton: {
    width: 75,
    height: 14,
    backgroundColor: tokens.colors.$houseLight,
    alignSelf: 'center',
  },
  navigation: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    height: 66,
  },
});
