import { ServiceMode } from '@rbi-ctg/menu';

export type CartOptions = {
  throwOnError?: boolean;
};

export type IDeliveryInputAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  route?: string;
  state?: string;
  streetNumber?: string;
  zip?: string;
  country?: string;
  latitude: number;
  longitude: number;
};

export type IConvertCartEntry = {
  itemId: string;
  quantity: number;
  modifiersJson: any;
  isCombo: boolean;
  //Additional context data
  pickerSelections: Record<string, string>;
  storeId: string;
  serviceMode: ServiceMode;
};
