import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconArrowForward = (props: IconProps) => {
  const { size = 16, color, fill = '#01000B', ...otherProps } = props;

  return (
    <Svg width={size} height={size} viewBox="0 0 21 21" fill="none" color={color} {...otherProps}>
      <Path
        d="M14.356 11.125H4.25v-1.25h10.106L9.609 5.128l.891-.878 6.25 6.25-6.25 6.25-.891-.878 4.747-4.747z"
        fill={fill}
      />
    </Svg>
  );
};
