import { useCallback } from 'react';

import useDialogModal from '@fhs-legacy/frontend/src/hooks/use-dialog-modal';

import { useMenuPicker } from '../../state/menu-picker';

type ConfirmUnsavedCustomizationModalParams = {
  onOpen?: () => void;
  onClose?: () => void;
};

export const useConfirmUnsavedCustomizationModal = ({
  onOpen,
  onClose,
}: ConfirmUnsavedCustomizationModalParams = {}) => {
  const {
    setCustomizationSelections,
    setDraftCustomizationSelections,
    draftCustomizationSelections,
    customizationSelections,
  } = useMenuPicker();

  const [ConfirmationDialog, openConfirmationDialog] = useDialogModal({
    onConfirm: useCallback(() => {
      setCustomizationSelections(draftCustomizationSelections);
      onClose?.();
    }, [draftCustomizationSelections, onClose, setCustomizationSelections]),
    onCancel: useCallback(() => {
      setDraftCustomizationSelections(customizationSelections);
      onClose?.();
    }, [customizationSelections, onClose, setDraftCustomizationSelections]),
    onOpen,
    showCancel: true,
    modalAppearanceEventMessage: '',
  });

  const confirmationDialog = (
    <ConfirmationDialog
      heading="You Have Unsaved Customizations"
      body="Are you sure you want to leave without saving your customizations?"
      confirmLabel="Save Customizations"
      cancelLabel="Don't Save"
    />
  );

  return {
    confirmationDialog,
    openConfirmationDialog,
  };
};
