import { useCallback } from 'react';

import { OfferDetail } from '@fhs/backend/amplify/functions/_temp/graphql/API';
import { addOfferToCart } from '@fhs/cart';
import { useMutation } from '@fhs/client';
import { IIncentiveSelection } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { OfferType } from '@fhs-legacy/frontend/src/generated/rbi-graphql';
import { EventTypes, useCRMEventsContext } from '@fhs-legacy/frontend/src/state/crm-events';
import {
  actions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '@fhs-legacy/frontend/src/state/global-state';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { LoyaltyOffer } from '@fhs-legacy/frontend/src/state/loyalty/types';
import { createTextBlock } from '@fhs-legacy/frontend/src/utils/sanity';
import { useToast } from '@fhs-legacy/universal-components';

import { SimplyOfferEventNames } from '../analytics';
import { useReplaceIncentiveUiStore } from '../modals';
import { LoyaltyCmsOfferV2, LoyaltyCmsOfferV2Incentive, PricingStrategyEnum } from '../types';

// TODO: This type is a hack, we need to sync between all screens and types from amplify to have the type consistent
export type Offer = Pick<
  OfferDetail,
  | 'id'
  | 'cmsId'
  | 'description'
  | 'incentiveType'
  | 'name'
  | 'pricingStrategy'
  | 'requireGuideFlow'
  | 'discountFees'
> & { isAvailable?: boolean | null };

interface IAddIncentiveToCartParams {
  offer: Offer;
  selections?: IIncentiveSelection[];
  showMessage?: boolean;
  callbacks?: IAddIncentiveToCartServiceCallbacks;
}

interface IAddIncentiveToCartServiceCallbacks {
  onSuccess?: () => void;
  onDismiss?: () => void;
}

export function useAddIncentiveToCart({
  onSuccess,
  onDismiss,
}: IAddIncentiveToCartServiceCallbacks = {}) {
  const { logRBIEvent } = useCRMEventsContext();
  const { mutateAsync } = useMutation(addOfferToCart);
  const { show: showReplaceIncentiveModal } = useReplaceIncentiveUiStore();
  const dispatch = useAppDispatch();
  const useSimplyBetterCart = useFlag(
    LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE_OFFER_INTEGRATION
  );
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );
  const hasAppliedRewards = appliedLoyaltyRewardsArray && appliedLoyaltyRewardsArray.length > 0;
  const hasAppliedOffers = appliedOffers.length > 0;

  const toast = useToast({
    placement: 'bottom',
  });

  const addIncentiveToCartService = useCallback(
    async ({ offer }: IAddIncentiveToCartParams) => {
      if (!offer || !offer.id) {
        return;
      }
      return mutateAsync({ offerId: offer.id, entries: [] });
    },
    [mutateAsync]
  );

  const _addIncentiveToCart = useCallback(
    (offer: Offer, selections?: IIncentiveSelection[], showMessage?: boolean) => {
      if (!offer.pricingStrategy) {
        return;
      }
      const { cmsId, description, id, incentiveType, name } = offer;

      const incentives: LoyaltyCmsOfferV2Incentive[] = mapPricingStrategyToIncentives(offer);

      const mapOfferV2ToV1: LoyaltyCmsOfferV2 = {
        __typename: 'SystemwideOfferV2',
        _type: 'simplyOffer',
        _id: id,
        description: { localeRaw: createTextBlock([{ text: description, marks: [] }]) },
        id: id,
        incentives,
        loyaltyEngineId: id,
        name: { localeRaw: createTextBlock([{ text: name, marks: [] }]) },
      };
      // casting to minimize changes on all V1 files
      dispatch(actions.loyalty.setCmsOffersV2([mapOfferV2ToV1 as unknown as LoyaltyOffer]));

      dispatch(
        actions.loyalty.selectedOfferV2({
          offer: mapOfferV2ToV1 as unknown as LoyaltyOffer,
          selections,
        })
      );
      dispatch(
        actions.loyalty.setAppliedOffers([
          {
            id: mapOfferV2ToV1.loyaltyEngineId,
            type: OfferType.GLOBAL,
            cartId: 'discount-offer',
            isStackable: false,
            isSurprise: false,
            cmsId,
          },
        ])
      );
      if (showMessage) {
        toast.show({
          text: 'Offer Successfully Applied!',
          variant: 'positive',
        });
      }

      logRBIEvent({
        name: SimplyOfferEventNames.OFFER_ADDED_TO_CART,
        type: EventTypes.Other,
        attributes: { offerId: id, incentiveType, sanityId: cmsId, selections },
      });
    },
    [dispatch, toast, logRBIEvent]
  );

  const removeRewards = useCallback(() => {
    if (appliedLoyaltyRewardsArray && appliedLoyaltyRewardsArray?.length > 0) {
      const rewardCartIdsToRemove = appliedLoyaltyRewardsArray?.map(
        appliedReward => appliedReward?.cartId
      );

      dispatch(actions.loyalty.removeAppliedRewards({ cartIds: rewardCartIdsToRemove }));
    }
  }, [dispatch, appliedLoyaltyRewardsArray]);

  const addIncentiveToCart = useCallback(
    async ({ offer, selections, showMessage, callbacks }: IAddIncentiveToCartParams) => {
      const successCallback = callbacks?.onSuccess ?? onSuccess;
      if (hasAppliedRewards || hasAppliedOffers) {
        showReplaceIncentiveModal({
          onConfirm: async () => {
            dispatch(actions.loyalty.resetAppliedOffers());
            removeRewards();
            _addIncentiveToCart(offer, selections, showMessage);
            successCallback?.();
          },
          onDismiss: callbacks?.onDismiss ?? onDismiss,
        });
        return;
      }
      _addIncentiveToCart(offer, selections, showMessage);
      successCallback?.();
    },

    [
      _addIncentiveToCart,
      showReplaceIncentiveModal,
      onSuccess,
      onDismiss,
      dispatch,
      hasAppliedRewards,
      hasAppliedOffers,
      removeRewards,
    ]
  );
  return {
    addIncentiveToCart: useSimplyBetterCart ? addIncentiveToCartService : addIncentiveToCart,
  };
}

function mapPricingStrategyToIncentives(offer: Offer) {
  const { incentiveType, pricingStrategy: pricingStrategyDetails, discountFees } = offer;
  const { pricingStrategy, type, value } = pricingStrategyDetails ?? {};

  if (!pricingStrategy) {
    return [];
  }

  switch (pricingStrategy) {
    case PricingStrategyEnum.DISCOUNT: {
      if (type && value) {
        return [
          {
            discountType: type,
            discountValue: value,
            _type: pricingStrategy,
            incentiveType,
            discountFees,
          },
        ];
      }
    }

    default:
      return [
        {
          _type: pricingStrategy,
          incentiveType,
          discountFees,
        },
      ];
  }
}
