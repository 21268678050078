import { useLocalSearchParams } from 'expo-router';
import { Platform, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

import { ErrorCard } from '@fhs/ui';

import { useOfferDetailUi } from '../../queries/loyalty.queries';
import { useIsItemInCart, useOfferCart } from '../../state/offer-guide-cart-state';

import { Content } from './content';
import { ItemInCart } from './item-in-cart';
import { OfferGuideLoading } from './offer-guide-loading';
import { Summary } from './summary';

export const FadeView = Platform.select({
  native: ({ children, style = {} }) => (
    <Animated.View style={style} entering={FadeIn.duration(500)} exiting={FadeOut}>
      {children}
    </Animated.View>
  ),
  web: ({ children, style = {} }) => <View style={style}>{children}</View>,
}) as any;

export function ContentRenderer() {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const { isLoading, isError, refetch } = useOfferDetailUi(offerId);

  const { offerInfo, showSummary } = useOfferCart();

  const isItemInCart = useIsItemInCart();

  if (isLoading) {
    return <OfferGuideLoading />;
  }

  if (isError) {
    return (
      <ErrorCard
        description="We ran into an issue loading the content of this page. Please try again."
        buttonTitle="Reload"
        buttonHandle={refetch}
      />
    );
  }

  if (!offerInfo?.isAvailable) {
    return (
      <ErrorCard
        hideButton
        title="Not Available"
        description="This offer is no longer available. Please select a different offer."
      />
    );
  }

  if (isItemInCart) {
    return (
      <FadeView style={{ flex: 1 }}>
        <ItemInCart />
      </FadeView>
    );
  }

  if (showSummary) {
    return (
      <FadeView style={{ flex: 1 }}>
        <Summary />
      </FadeView>
    );
  }

  return <Content />;
}
