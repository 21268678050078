import { Image } from 'expo-image';
import { useLayoutEffect, useMemo } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, View } from 'react-native';

import { formatCentsToDollars } from '@fhs/menu/src/utils';
import { ActionSheet, Button, IconCartFill, Skeleton, Text, createMqStyles, tokens } from '@fhs/ui';
import { useLoyaltyOfferPricingQuery } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { EventTypes, useCRMEventsContext } from '@fhs-legacy/frontend/src/state/crm-events';

import { SimplyOfferEventNames } from '../../analytics';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import {
  useAddGuideOfferToCart,
  useEditStepSelectedEntry,
  useOfferCart,
} from '../../state/offer-guide-cart-state';

import { BottomButtonContainer } from './bottom-button';
import { SummaryStepItem, SummaryStepItemProps } from './summary-step-item';

const renderItem = ({ item }: ListRenderItemInfo<SummaryStepItemProps>) => {
  return <SummaryStepItem {...item} />;
};

const renderStepSeparator = () => {
  return <View style={styles.stepSeparator} />;
};

const ButtonPrice = ({
  price,
  loading,
  error,
}: {
  price?: number;
  loading: boolean;
  error: boolean;
}) => {
  const priceFormatted = useMemo(
    () => (typeof price === 'number' ? formatCentsToDollars(price) ?? '' : ''),
    [price]
  );

  if (loading) {
    return <Skeleton style={styles.skeleton} />;
  }
  if (error) {
    return null;
  }

  return <Button.Text style={{ alignSelf: 'center' }}>{priceFormatted}</Button.Text>;
};

export function Summary() {
  const mqStyles = useMqStyles();
  const { store } = useLoyaltyLegacyStates();
  const { logRBIEvent } = useCRMEventsContext();
  const { steps: offerSteps, offerInfo } = useOfferCart();
  const { image, description, termsAndConditions, name } = offerInfo ?? {};
  const { addGuideOfferToCart } = useAddGuideOfferToCart();
  const { editStepSelectedEntry } = useEditStepSelectedEntry();
  const dryRunCartForPricing = useMemo(() => addGuideOfferToCart(true), []);
  const { data, loading, error } = useLoyaltyOfferPricingQuery({
    variables: {
      where: {
        appliedOfferId: offerInfo?.id ?? '',
        storeId: store.number ?? '',
        cartEntries: dryRunCartForPricing?.backendCartEntries,
        incentiveSelections: dryRunCartForPricing?.selections,
      },
    },
  });

  useLayoutEffect(() => {
    if (offerSteps.length === 1) {
      addGuideOfferToCart();

      return;
    }
    logRBIEvent({
      name: SimplyOfferEventNames.OFFER_GUIDE_SUMMARY_SHOWN,
      type: EventTypes.Other,
      attributes: {
        offerId: offerInfo?.id,
        sanityId: offerInfo?.cmsId,
        selections: offerSteps.map(step => ({
          key: step.key,
          rank: step.rank,
          selectedEntry: {
            itemId: step.selectedEntry?._id,
            lineId: step.selectedEntry?.cartId,
          },
        })),
      },
    });
  }, []);

  const summaryStepItemProps = useMemo(
    () =>
      offerSteps.map<SummaryStepItemProps>((offerStep, index) => ({
        selectedEntry: offerStep.selectedEntry,
        onPressButton: () => editStepSelectedEntry(offerStep, index),
      })),
    [offerSteps, editStepSelectedEntry]
  );

  return (
    <View style={{ flex: 1 }}>
      <ActionSheet.ScrollView style={styles.container} overScrollMode="never">
        <View style={styles.imageContainer}>
          <Image source={{ uri: image }} contentFit="contain" style={{ height: 216 }} />
        </View>
        <View style={styles.content}>
          <Text.Heading type="one" style={{ textAlign: 'center' }}>
            {name}
          </Text.Heading>
          {description && (
            <Text.Ui size="md" style={styles.subtitle}>
              {description}
            </Text.Ui>
          )}
          <View style={styles.stepsContainer}>
            <FlatList
              data={summaryStepItemProps}
              renderItem={renderItem}
              ItemSeparatorComponent={renderStepSeparator}
            />
          </View>
          <View style={styles.separator} />
          <Text.Paragraph size="sm" style={styles.termAndCondition}>
            {termsAndConditions}
          </Text.Paragraph>
        </View>
      </ActionSheet.ScrollView>
      <BottomButtonContainer>
        <Button size="xl" onPress={() => addGuideOfferToCart(false)}>
          <View style={[mqStyles.buttonContainer, !!error && { justifyContent: 'center' }]}>
            <View style={styles.buttonContent}>
              <Button.Icon>
                <IconCartFill />
              </Button.Icon>
              <Button.Text>Add Offer To Cart</Button.Text>
            </View>
            <ButtonPrice
              price={data?.loyaltyOfferPricing?.offerFinalPrice}
              loading={loading}
              error={!!error}
            />
          </View>
        </Button>
      </BottomButtonContainer>
    </View>
  );
}

const useMqStyles = createMqStyles({
  buttonContainer: {
    $base: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      paddingHorizontal: 18,
    },
    $gteDesktop: {
      paddingHorizontal: 28,
    },
  },
});

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderColor: tokens.colors.$blackOpacity04,
  },
  imageContainer: {
    borderBottomWidth: 1,
    borderColor: tokens.colors.$blackOpacity04,
  },
  content: {
    marginVertical: 24,
    paddingHorizontal: 16,
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 4,
  },
  stepsContainer: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
  },
  stepSeparator: {
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  separator: {
    marginTop: 20,
    width: '100%',
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  termAndCondition: {
    color: tokens.colors.$blackOpacity55,
    marginTop: 20,
  },
  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  skeleton: {
    width: 70,
    height: 20,
    alignSelf: 'center',
  },
});
