import { Image } from 'expo-image';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Quantity, Text, XStack } from '@fhs/ui';

import { ListItemUnavailableBadge } from './list-item-unavailable-badge';
import { listStyles } from './shared-styles';
import type { ListItemType } from './types';
import { optimizeSanityImageUri } from './utils';

type ListItemStepperProps = ListItemType & {
  disabled?: boolean;
  value: string;
  onChangeValue: (nextValue: string) => void;
  options: Array<{ label: string; value: string }>;
};

export function ListItemStepper({ minHeightStyle = 'static', ...props }: ListItemStepperProps) {
  return (
    <View
      style={[
        listStyles.item,
        minHeightStyle === 'static' && listStyles.minHeightStyleStatic,
        props.isLastItem && listStyles.lastItem,
      ]}
    >
      {props.image?.asset?.uri && (
        <Image
          contentFit="contain"
          style={[listStyles.image, props.disabled && listStyles.imageDisabled]}
          alt={props.image.altText}
          source={{ uri: optimizeSanityImageUri(props.image.asset.uri) }}
          placeholder={{ blurhash: props.image.asset.blurHash }}
        />
      )}
      <View style={listStyles.titleTextContainer}>
        <View>
          <Text.Ui
            weight={props.titleFontWeight ?? 'semibold'}
            size="md"
            numberOfLines={1}
            style={props.disabled && listStyles.textDisabled}
          >
            {props.title}
          </Text.Ui>
        </View>
        {Boolean(props.subtitle) && (
          <View>
            <Text.Ui size="sm" numberOfLines={1} style={listStyles.subtitle}>
              {props.subtitle}
            </Text.Ui>
          </View>
        )}
      </View>
      {props.disabled ? (
        <ListItemUnavailableBadge />
      ) : (
        <XStack style={listStyles.indicatorWithTextWrapper}>
          {Boolean(props.indicatorText) && (
            <Text.Ui size="sm" numberOfLines={1} style={listStyles.indicatorText}>
              {props.indicatorText}
            </Text.Ui>
          )}
          <Quantity
            options={props.options}
            value={props.value}
            onChangeValue={props.onChangeValue}
            size="sm"
          >
            <Quantity.Stepper style={styles.quantityStepper} />
          </Quantity>
        </XStack>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  quantityStepper: {
    minWidth: 160,
    justifyContent: 'space-between',
  },
});
